<template>
  <div class="row currencyQuotes">
    <b-col lg="12">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h2 class="title text-center pt-1 mt-1">CURRENCY QUOTE</h2>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <b-row class="mt-3">
            <b-col lg="2" >
              <label>Base Currency</label>
            </b-col>
            <b-col lg="2" >
              <multiselect 
                v-model="form.base_currency"
                label="iso4217_code"
                track-by="id"
                placeholder="Base Currency"
                open-direction="bottom"
                :options="BaseCurrencySearchOptions"
                :searchable="true"
                :loading="isBaseCurrencySearch"
                :multiple="false"
                :taggable="false"
                @search-change="setOptionCounterCurrency('base')"
                required
              >
              </multiselect>
            </b-col>
            <b-col lg="2">
              <label>Counter Currency *</label>
            </b-col>
            <b-col lg="2">
              <multiselect
                v-model="form.counter_currency"
                label="iso4217_code"
                track-by="id"
                placeholder="Counter Currency"
                open-direction="bottom"
                :options="CounterCurrencySearchOptions"
                :searchable="true"
                :loading="isCounterCurrencySearch"
                @input="counterSelected"
                :multiple="false"
                :taggable="false"
                @search-change="setOptionCounterCurrency('counter')"
                required
              >
              </multiselect>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="2" ><label>Period</label></b-col>
          <b-col lg="4">
            <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
              >
              <template v-slot="{ inputValue, inputEvents, isDragging }">
                <div id="betweenPeriod" class="rows">
                  <input
                    id="startPeriod"
                    class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />
                  <span class="p-1">to</span>
                  <input
                    id="endPeriod"
                    class="flex-grow pl-6 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    :max-date="new Date()"
                  />
                </div>
              </template>
            </v-date-picker>
      </b-col>
      <b-col lg="2">
        <button
        class="btn btn-block btn-primary"
        @click="actionSearchCurrency"
      >
        Search
      </button>
      </b-col>
    </b-row>
    
    <div class="row justify-content-end">
      <div class="col-sm-auto mt-7 mb-1">
          <router-link class="btn btn-sm btn-dark " :to="{ path: '/currencyQuotes/create'}">
            <em style="width: 30px; height: 75%" class="fa fa-plus"></em>
          </router-link>
          <button class="btn btn-sm btn-dark " @click="refreshCurrency">
            <em style="width: 30px; height: 75%"  class="fa fa-refresh"></em>
          </button>
          <label class="col-sm-auto">Count </label>
          <strong class="col-sm-auto">{{ formatNumber(this.totalRows) }}</strong>
        </div>
      </div>
      <br/>

      <div class="table-responsesive">
      <b-table 
          sticky-header="600px"
        :items="items"
        :fields="fields"
        :busy="isLoading"
        :isLoading="isLoading"
        show-empty
        responsive="xl"
        hover
        sort-icon-left
      >
        <template #cell(No)="row">
          {{ (currentPage - 1) * perPage + (row.index + 1)}}
        </template>
        <template #cell(Action)="row">
          <router-link :to="{path :'/currencyQuotes/' + row.item.id}" class="link"> 
            {{row.item.name }}
          </router-link>
        </template>
        <template #cell(quote)="row">
          {{ formatPrice(row.item.quote, row.item.counter_currency) }}
        </template>
        <template v-slot:cell(action_edit_is_active)="row">
          <button v-if="row.item.is_active"
            class="btn btn-sm btn-success "
            type="button"
            @click="updateIsActiveAction(row.item.id,row.item.is_active)"
          >
          Active
          </button>
          <button v-else  class="btn btn-sm btn-danger"
            type="button"
            @click="updateIsActiveAction(row.item.id,row.item.is_active)"
          >
          InActive
          </button>
        </template>

        <template #cell(action_edit_currencyQuotes)="row">
          <router-link class="btn btn-default"
            :to="{ path: '/currencyQuotes/Edit/'+ row.item.id }"
          >
            <i class="fa fa-edit">
            </i>
          </router-link>
        </template>
      </b-table>
      </div>
    </div>
    <b-row class="justify-content-start ml-1">
      <b-col lg="3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
    </div>
  </div>
</b-col>
</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from 'moment';
export default {
  name: "CurrencyQuotesDisplay",
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      debounce: null,
      BaseCurrencySearchOptions: [], 
      CounterCurrencySearchOptions: [],
      isBaseCurrencySearch: false,
      isCounterCurrencySearch: false,
      form: {
        base_currency:null,
        counter_currency:null,
      },
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      fields: [
        { key:'No', label: 'No'},
        { key:'base_currency', label: 'Base Currency'},
        { key:'counter_currency', label: 'Counter Currency'},
        { key:'quote', label: 'Quotes'},
        { key:'source', label: 'Source'},
        { key :'valid_from', label:'Valid From',formatter: function(value) {
          return moment(value).format('DD-MM-YYYY');
        },},
        { key :'valid_to', label:'Valid To',formatter: function(value) {
          return moment(value).format('DD-MM-YYYY');
        },},
        { key :'created', label:'Created At',formatter: function(value) {
          return moment(value).format('DD-MM-YYYY');
        },},
        { key :'action_edit_currencyQuotes', label:'Edit Currency'},
        { key :'action_edit_is_active', label:'Edit Is Active'},
      ]
    };
  },
  created() {
    this.setOptionCounterCurrency('all');
    this.actionGetCurrency();
  },
  watch: {
    currentPage: function() {
      this.actionGetCurrency();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Currency | Content Management System Ebooks Gramedia.com';
      }
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
        return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: this.errorMessage,
        });
      }
    },
  computed: {
    ...mapState({
      isLoading: (state) => state.currencyQuotes.isLoading,
      isError: (state) => state.currencyQuotes.isError,
      totalRows: (state) => state.currencyQuotes.totalRows,
      items: (state) => state.currencyQuotes.items,
      errorMessage: (state) => state.currencyQuotes.errorMessage,
    }),
  },
  mounted() {
    this.actionGetCurrency();
  },
  methods: {
    ...mapActions("currencyQuotes", ["fetchCurrencyAll","searchCurrencyQuotes","updateIsActive"]),
    ...mapActions("currency", ["fetchCurrency"]),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 20,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 20,
          });
        }
        return formatter.format(value);
      }
      return "0";
    }, refreshCurrency() {
      this.form.base_currency = null;
      this.form.counter_currency = null;
      this.range =  {
        start: null,
        end: null,
      }
      this.actionGetCurrency();
    },
    actionGetCurrency() {
      this.items_search = [];
      let payload = {
        page: this.currentPage??1,
        limit: this.perPage??10,
      };
      this.fetchCurrencyAll(payload);
    }, counterSelected () {
      if (this.form.base_currency.iso4217_code == this.form.counter_currency.iso4217_code) {
        this.counter_currency = null;
        return this.baseAlert({
          icon: 'error',
          title: 'Error',
          text: 'Base Currency And Counter Currency Must not same',
        });
      }
  }, async setOptionCounterCurrency(type = 'all'){
    if(type=='base'|| type=='all') {
      this.isBaseCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.BaseCurrencySearchOptions = response.data.data.rows;
					})
        }, 1200);
      this.isBaseCurrencySearch = false;
    } if(type=='counter' || type == 'all'){
      this.isCounterCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.CounterCurrencySearchOptions = response.data.data.rows;
				
					})
        }, 1200);
      this.isCounterCurrencySearch = false;
    }
  },
    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      })
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return '0';
    },showAlert(data) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon: data.success ? 'success' : 'error',
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
    actionSearchCurrency () {
      const data= this.form;
      let base_currency = data.base_currency;
      let counter_currency = data.counter_currency;
      if (base_currency != null || counter_currency != null) {
        if(base_currency == null || counter_currency == null) {
            return this.baseAlert({
            icon: 'error',
            title: 'Error',
            text: "base currency and counter currency must be input",
          });
        }
        base_currency = base_currency.iso4217_code;
        counter_currency = counter_currency.iso4217_code;
      }
      let start = this.range.start, end = this.range.end;
      if(start!= null ||end != null) {
        if(start == null || end == null) {
          return this.baseAlert({
            icon: 'error',
            title: 'Error',
            text: "period from and period to must be input",
          });
        }
        start = moment(this.range.start).format('YYYY-MM-DD');
        end = moment(this.range.end).format('YYYY-MM-DD');
      }
      this.isLoadingSearch = true;
      const payload = {
        base_currency,
        counter_currency,
        valid_from : start,
        valid_to : end,
      }
      this.isLoadingSearch = false;
      return this.searchCurrencyQuotes(payload);
    },
    baseAlert(data) {
      this.$swal({
        icon: data.icon,
        title: data.title,
        text: data.text,
      })
    },
    updateIsActiveAction (id,is_active){
      let stringActive='';
      if(is_active){
        stringActive = 'InActive';
      } else {
        stringActive = 'Active'
      }
      this.$swal
      .fire({
        title: "Are you sure?",
        text: `You Want To Change Into ${stringActive}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload={
            id,is_active
          }
          this.updateIsActive(payload).then((response) => {
            const message = response.message;
            if(response.result){
              this.showAlert({
                success: true,
                message: message,
                title: 'Success',
              });
            }else{
              this.baseAlert({
                icon: 'error',
                title: 'Error',
                text: message,
              });
            }
            return this.actionGetCurrency()
          })
        }
      });
    }
  },
}
</script>
<style scoped>

</style>
